/* src/views/GolfLessons.css */

.golf-page {
  padding-top: 0px; 
}

.golf-lessons-container h2{
    font-family: 'GTSD Bold';
    font-size: 70px;
    color: #2E2E2E;
    padding-top: 2%;
}
.coaches-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 20px;
}

.coach-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #F3E8DD;
  padding: 20px;
  border-radius: 10px;
  transition: transform 0.3s ease;
}

.coach-card:hover {
  transform: scale(1.05);
}

.coach-photo {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 10px;
  margin-bottom: 20px;
}

.coach-card h5 {
  margin-top: 10%;
  margin-bottom: 0.5em;
  font-family: 'GTSD Bold';
  font-size: 24px;
}

.coach-card p {
  font-family: 'AP Pro Medium';
  color: #2E2E2E;
  font-size: large;
  text-align: center;
}

.button {
  display: inline-block;
  padding: 10px 20px;
  font-size: 16px;
  text-align: center;
  text-decoration: none;
  background-color: #2D3C34;
  color: white;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border-radius: 20px;
  font-family: 'AP Pro Medium';
}

.button:hover {
  background-color: #2D3C34;
}

.full-width-button {
  width: 100%;
  text-align: center;
}

@media (max-width: 768px) {
  .coaches-container {
    display: flex;
    flex-direction: column;
  }

  .coach-card {
    text-align: left; 
  }
}

.button {
  padding: 10px 20px;
  border: 2px solid transparent;
  background-color: #2D3C34;
  color: #FFFBB3;
  font-size: 16px;
  border-radius: 20px;
  outline: none;
  cursor: pointer;
  font-family: 'AP Pro Medium';
}

.button:hover {
  border-color: #FFFBB3;
}

.button:active {
  background-color: #fff;
}
