@font-face {
    font-family: 'GTSD Italic Bold'; 
    src: url('../assets/fonts/GT-Super/GT-Super-Display-Bold-Italic-Trial.otf') format('opentype');
}

@font-face {
    font-family: 'AS Light'; 
    src: url('../assets/fonts/Assistant/Assistant-ExtraLight.ttf') format('opentype');
}

@font-face {
    font-family: 'GTSD Bold'; 
    src: url('../assets/fonts/GT-Super/GT-Super-Display-Bold-Trial.otf') format('opentype');
}

@font-face {
    font-family: 'AP Pro Medium'; 
    src: url('../assets/fonts/Apercu/Apercu Pro Regular.otf') format('opentype');
}

@font-face {
    font-family: 'GTT Bold'; 
    src: url('../assets/fonts/GT-Super/GT-Super-Text-Bold-Trial.otf') format('opentype');
}

@font-face {
    font-family: 'GTSD Regular'; 
    src: url('../assets/fonts/GT-Super/GT-Super-Display-Regular-Trial.otf') format('opentype');
}

.Content-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    overflow-x: hidden;
    background-color: #F3E8DD;
}
/*
.homevideo {
    min-width: 100%;
    min-height: 100%;
    width: auto; 
    height: auto; 
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    font-weight: bold;
}
*/
/*
.homevideo {
    min-width: 100%;
    min-height: 100%;

    width: 100%;
    height: 100vh; 
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    font-weight: bold;
    overflow: hidden; 
}
*/
.homevideo {
    width: 100%;
    height: 100vh; /* Ensures the homevideo section takes the full viewport height */
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden; /* Prevents content overflow */
}

.homevideo video {
    width: 100%;
    height: 100%;
    object-fit: cover; 
    position: absolute; 
    top: 0;
    left: 0;
}

.homevideo .welcome-word {
    position: relative;
    z-index: 2; /* Ensure content is above the video */
    text-align: center;
    color: #FFFBB3; /* Ensure the content is visible */
    display: flex;
    flex-direction: column; /* Align titles in a column */
    align-items: center; /* Center titles horizontally */
    justify-content: center; /* Center titles vertically */
    width: auto; /* Use auto to prevent it from stretching */
    padding: 0 200px; /* Add padding to ensure it doesn't align to the left */
}

.welcoming-title {
    font-family: 'GTSD Bold';
    font-size: 60px;
    margin-bottom: 20px; 
    text-align: center; 
    transform: translateX(-420px);
}

.hometitle {
    font-family: 'GTSD Italic Bold';
    font-size: 100px;
    text-align: center; /* Center align the text */
    white-space: nowrap; /* Prevent wrapping initially */
    width: 100%;
    overflow: hidden; /* Prevent overflow */
}

/*
.welcoming-title{
    top: 270px;
    left: 180px;
    font-family: 'GTSD Bold';
    font-size: 60px;
    color: #FFFBB3;
    position: absolute;
}

.hometitle{
    position: absolute;
    font-family: 'GTSD Italic Bold';
    font-size: 100px;
    color: #FFFBB3;
}
*/
.book-tee-time, .book-driving-range {
    margin-top: 20px;
    text-align: center;
    position: absolute;
    top: 75%;
    left: 50%;
    transform: translateX(-50%);
    font-family: 'GTSD Bold';
}

.book-tee-time {
    left: 50%;
}

.book-driving-range {
    left: 60%;
}

.book-tee-time-button, .book-driving-range-button {
    display: inline-block;
    padding: 10px 20px;
    font-size: 30px;
    color: #2E2E2E !important;
    background-color: #FFFBB3;
    border-radius: 5px;
    text-decoration: none;
    transition: background-color 0.3s ease, opacity 0.3s ease;
}

.book-tee-time-button:hover, .book-driving-range-button:hover {
    background-color: #F3E8DD;
    opacity: 0.5;
    text-decoration: none; /* Remove underline on hover */
}



.explore-arrow {
    position: absolute;
    bottom: 20px; 
    left: 50%;
    transform: translateX(-50%);
    cursor: pointer;
    font-family: 'AS Light';
    color: #FFF;
    font-size: 20px;
    display: flex; 
    flex-direction: column; 
    align-items: center;
    justify-content: center;
}

.information-section{
    background-color: #F3E8DD;
}

.information-section h2 {
    font-size: 80px;
    margin-bottom: 20px;
    font-family: 'GTSD Bold';
    left: 50%;
    color: #2E2E2E;
    text-align: center;
    margin-top: 20px;
}

.information-section p{
    font-family: 'AP Pro Medium';
    color: #2E2E2E;
    text-align: center;
    max-width: 1200px;
    margin: 0 auto;
    margin-top: 50px;
    line-height: 150%;
}

.photo-gallery {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: auto;
    gap: 0;
    margin-top: 50px;
}

.HPimage-container {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
}

.image-text {
    position: absolute;
    color: white;
    font-size: 24px;
    text-align: center;
    z-index: 2;
    font-family: 'GTSD Bold';
    top: 50%; 
    left: 50%; 
    transform: translate(-50%, -50%); 
}

.HPimage-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease, opacity 0.3s ease;
}

/* Target the first image to span across all columns */
.photo-gallery .HPimage-container:first-child {
    grid-column: span 2;
}

/* Hover effects */
.HPimage-container:hover img {
    transform: scale(1.1);
    opacity: 0.5;
}

.HPimage-container::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(45, 60, 52, 0);
    transition: background-color 0.3s ease;
}

.HPimage-container:hover::before {
    background-color: rgba(45, 60, 52, 1);
}

.whatsNewContainer {
    display: flex;
    justify-content: space-between;
    margin: 20px 0;
    margin-top: 30px; 
    margin-bottom: 50px; 
}

.whatsNewSection {
    flex: 1;
    margin: 0 10px;
    text-align: center;
    border: none;
}

.whatsNewSection h3 {
    margin-bottom: 10px;
    font-family: 'GTSD Regular';
    color: #2E2E2E;
    font-size: 20px;
}

.whatsNewSection img {
    max-width: 100%;
    height: auto;
    transition: 0.3s;
}

.whatsNewSection img:hover {
    transform: scale(1.1);
}

.whatsNewSection img {
    width: 700px;   
    height: 400px; 
    object-fit: cover; 
    border-radius: 50px; 
}

.whatsNewSection button {
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
    text-align: center;
}

.google-map-container {
    width: 100%; 
    height: 800px; 
    position: relative; 
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    z-index: 3;
}

.latest-news-section {
    width: 100%;
    padding: 50px;
    background-color: #F3E8DD; 
    box-sizing: border-box;
}
  
.latest-news-section h2 {
    font-size: 80px;
    margin-bottom: 20px;
    font-family: 'GTSD Bold';
    left: 50%;
    color: #2E2E2E;
    text-align: center;
    margin-top: 20px;
}
  
.latest-news-section p {
    font-family: 'AP Pro Medium';
    color: #2E2E2E;
    text-align: center;
    max-width: 1200px;
    margin: 0 auto;
    line-height: 150%;
}
  
.how-to-find-us-section {
    width: 100%;
    background-color: #F3E8DD; 
    box-sizing: border-box;
}
  
.how-to-find-us-section h2 {
    font-size: 80px;
    margin-bottom: 20px;
    font-family: 'GTSD Bold';
    left: 50%;
    color: #2E2E2E;
    text-align: center;
    margin-top: 20px;
    padding-top: 5%;
}

.how-to-find-us-section p {
    font-family: 'AP Pro Medium';
    color: #2E2E2E;
    text-align: center;
    max-width: 1200px;
    margin: 0 auto;
    margin-top: 50px;
    line-height: 150%;
    margin-bottom: 5%;
}
  
/* TimelineEvent */
.timeline-event {
    display: flex;
    align-items: center;
    margin-bottom: 30px; /* Adjust as needed */
}
  
.year {
    min-width: 80px; /* Adjust as needed */
    font-weight: bold;
    font-size: 1.5em; /* Adjust as needed */
    color: #333; /* Adjust as needed */
    margin-right: 20px; /* Space between year and content */
}
  
.content {
    flex: 1;
    padding: 10px;
    border-left: 2px solid #666; /* Adjust as needed */
    margin-left: 20px;
    position: relative;
}
  
.content::before {
    content: '';
    position: absolute;
    top: 50%;
    left: -6px; /* Adjust as needed */
    width: 10px; /* Adjust as needed */
    height: 10px; /* Adjust as needed */
    border-radius: 50%;
    background-color: #666; /* Adjust as needed */
    transform: translateY(-50%);
}

.GolfHeaderImage {
    overflow: hidden;
    width: 100%;
    height: 100%;
    object-fit: cover;
    background-position: center; 
}

@media (max-width: 2560px) {
    
    .welcome-word {
        left: 245px;
    }

    .welcoming-title {
        font-size: 100px;
        transform: translateX(-547px);
    }

    .hometitle {
        font-size: 140px;
    }

    .book-tee-time-button {
        padding: 10px 30px;
        font-size: 50px;
    }

}

@media (max-width: 2500px) {
    .welcome-word {
        left: 215px;
    }
}

@media (max-width: 2450px) {
    .welcome-word {
        left: 190px;
    }
}

@media (max-width: 2400px) {
    .welcome-word {
        left: 170px;
    }
}

@media (max-width: 2350px) {
    .welcome-word {
        left: 145px;
    }
}

@media (max-width: 2300px) {
    .welcome-word {
        left: 125px;
    }
}

@media (max-width: 2250px) {
    .welcome-word {
        left: 150px;
    }

    .welcoming-title {
        font-size: 90px;
        transform: translateX(-517px);
    }

    .hometitle {
        font-size: 130px;
    }
}

@media (max-width: 2200px) {
    .welcome-word {
        left: 130px;
    }
}

@media (max-width: 2150px) {
    .welcome-word {
        left: 105px;
    }
}

@media (max-width: 2100px) {
    .welcome-word {
        left: 80px;
    }
}

@media (max-width: 2050px) {
    .welcome-word {
        left: 57px;
    }
}

@media (max-width: 2000px) {
    .welcome-word {
        left: 35px;
    }
}

@media (max-width: 1920px) {
    .welcome-word {
        left: 135px;
    }

    .welcoming-title {
        font-size: 70px;
        transform: translateX(-395px);
    }

    .hometitle {
        font-size: 100px;
    }

    .book-tee-time-button {
        padding: 10px 20px;
        margin-top: 10%;
        font-size: 20px;
    }
}

@media (max-width: 1850px) {
    .welcome-word {
        left: 135px;
    }
}

@media (max-width: 1800px) {
    .welcome-word {
        left: 105px;
    }
}

@media (max-width: 1750px) {
    .welcome-word {
        left: 85px;
    }
}

@media (max-width: 1700px) {
    .welcome-word {
        left: 58px;
    }
}

@media (max-width: 1650px) {
    .welcome-word {
        left: 35px;
    }
}

@media (max-width: 1600px) {
    .welcome-word {
        left: 9px;
    }
}

@media (max-width: 1560px) {
    .welcome-word {
        left: -3px;
    }

    .information-section p {
        padding: 0 100px;
        text-align: left;
    }

    .how-to-find-us-section p {
        padding: 0 100px;
    }
}

@media (max-width: 1500px) {
    .welcoming-title {
        font-size: 60px;
        transform: translateX(-395px);
    }

    .hometitle {
        font-size: 95px;
    }

    .book-tee-time-button {
        padding: 10px 20px;
        font-size: 30px;
    }

}


@media (max-width: 1250px) {
    video {       
        width: 105%;
    }

    .welcoming-title {
        font-size: 45px;
        transform: translateX(-300px);
    }

    .hometitle {
        font-size: 72px;
    }

    .photo-gallery {
        min-height:600px;
    }
}

@media (max-width: 912px) {
    .welcoming-title {
        transform: translateX(-288px);
    }

    .hometitle {
        font-size: 70px;
    }

    .photo-gallery {
        min-height:600px;
    }
}

@media (max-width: 850px) {
    .welcoming-title {
        font-size: 40px;
        transform: translateX(-215px);
    }

    .hometitle {
        font-size: 55px;
    }

    .photo-gallery {
        min-height:600px;
    }
}

@media (max-width: 768px) {
    .welcoming-title {
        font-size: 30px;
        transform: translateX(-185px);
    }

    .hometitle {
        font-size: 45px;
    }

    .photo-gallery {
        min-height:600px;
    }

    .information-section p, .how-to-find-us-section p {
        text-align: left;
    }
}

@media (max-width: 540px) {
    video {       
        width: 150%;
        
    }

    .welcoming-title{
        font-size: 30px;
        transform: translateX(-155px);
    }

    .hometitle {
        font-size: 40px;
    }

    .explore-arrow {
        font-size: 15px;
    }

    .information-section h2 {
        font-size: 45px;
    }

    .latest-news-section h2 {
        font-size: 45px;
    }

    .whatsNewContainer {
        flex-direction: column;
    }

    .how-to-find-us-section h2 {
        font-size: 45px;
    }

    .google-map-container {
        height: 300px;
    }

    .photo-gallery {
        min-height:600px;
    }

    .book-tee-time-button {
        font-size: 25px;
    }
}

@media (max-width: 500px) {
    .welcoming-title{
        font-size: 26px;
        transform: translateX(-136px);
    }

    .hometitle {
        font-size: 35px;
    }
}


@media (max-width: 452px) {
    .book-tee-time-button {
        font-size: 20px;
    }
}

@media (max-width: 430px) {
    video {       
        width: 170%;
    }

    .welcoming-title {
        font-size: 23px;
        transform: translateX(-115px);
    }

    .hometitle {
        font-size: 30px;
    }

    .explore-arrow {
        font-size: 15px;
    }

    .information-section h2 {
        font-size: 45px;
    }

    .latest-news-section h2 {
        font-size: 45px;
    }

    .whatsNewContainer {
        flex-direction: column;
    }

    .how-to-find-us-section h2 {
        font-size: 45px;
    }

    .google-map-container {
        height: 300px;
    }

    .photo-gallery {
        min-height:600px;
    }
}

@media (max-width: 414px) {
    .welcoming-title {
        left: 30px;
    }

    .photo-gallery {
        min-height:600px;
    }
}

@media (max-width: 390px) {
    .welcoming-title {
        left: 18px;
    }

    .hometitle {
        left: 18px;
    }

    .photo-gallery {
        min-height:600px;
    }

    .book-tee-time-button {
        font-size: 15px;
    }
}