@font-face {
  font-family: 'GTSD Italic Bold'; 
  src: url('../assets/fonts/GT-Super/GT-Super-Display-Bold-Italic-Trial.otf') format('opentype');
}

@font-face {
  font-family: 'AS Light'; 
  src: url('../assets/fonts/Assistant/Assistant-ExtraLight.ttf') format('opentype');
}

@font-face {
  font-family: 'GTSD Bold'; 
  src: url('../assets/fonts/GT-Super/GT-Super-Display-Bold-Trial.otf') format('opentype');
}

@font-face {
  font-family: 'AP Pro Medium'; 
  src: url('../assets/fonts/Apercu/Apercu Pro Regular.otf') format('opentype');
}

@font-face {
  font-family: 'GTST Bold'; 
  src: url('../assets/fonts/GT-Super/GT-Super-Text-Bold-Trial.otf') format('opentype');
}


.footer {
  background-color: #2D3C34;
  color: #FFFBB3;
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  min-height: 300px;
  z-index: 100;
  position: relative;
}

.footer-container {
  display: flex;
  flex-wrap: nowrap; /* Prevents the items from wrapping */
  align-items: center; /* Centers items vertically */
  justify-content: space-around; /* Distributes space around items */
  padding: 20px; /* Add some padding for spacing */
  margin-left: 3%;
}

.footer-section ul {
  display: flex;
  list-style: none;
  padding: 0;
  /*
  padding: 20;
  margin-left: 3%;
  */
  font-family: 'GTSD Bold';
  font-size: large;
  flex-wrap: wrap;
  /*
  min-width: 1000px;
  */
}

.footer-section ul li { 
  font-family: 'GTSD Bold'; 
  font-size: 20px; 
  line-height: 1.5; 
  vertical-align: middle; 
  margin-right: 30px; 
  cursor: pointer;
 
}

.footer-section ul li {
  margin-right: 30px; 
  font-size: 20px;
  cursor: pointer;
  transition: transform 0.5s ease-in-out; 
}

.footer-section ul li:hover {
  transform: scale(1.1); 
  margin-top: -10px; 
  color: #92917d; 
}


.contact-divider {
  height: 60px; 
  width: 2px; 
  background-color: #FFFBB3;
  margin: 0 80px;
}

.contact-section {
  margin: 0 20px;
}

.contact-section h4{
  flex-grow: 1;
  font-family: 'GTSD Bold';
}

.contact-section p{
  flex-grow: 1;
  font-family: 'AS Light';
}

.footer-rights {
  width: 100%;
  margin-top: 20px; 
  border-top: 2px solid #FFFBB3;
  display: flex;
  padding: 10px 0;
  font-family: 'GTSD Italic Bold';
}

.social-media {
  margin-left: auto;
  display: flex;
  align-items: center; 
  margin-top: 15px;
}

.social-media > * {
  margin-right: 10px;
}

.social-media-icon:hover {
  color: #92917d; 
  cursor: pointer;
}

.footer-section ul li a {
  font-family: 'GTSD Bold'; 
  font-size: 20px; 
  line-height: 1.5; 
  color: inherit; /* 继承父元素的颜色 */
  text-decoration: none; /* 移除下划线 */
  transition: color 0.3s ease; /* 平滑过渡颜色变化 */
}

.footer-section ul li a:hover {
  color: #92917d; /* 鼠标悬停时的颜色 */
  transform: scale(1.1); /* 稍微放大 */
  margin-top: -10px; /* 向上移动 */
}

.social-media-icon {
  color: #FFFBB3; /* 默认颜色 */
  transition: color 0.3s ease; /* 颜色变化时的过渡效果 */
  cursor: pointer;
}

.social-media-icon:hover {
  color: #92917d; /* 鼠标悬停时的颜色 */
}

.link-button {
  background: none;
  border: none;
  color: inherit;
  text-decoration: none;
  cursor: pointer;
  font-size: 20px;
  font-family: 'GTSD Bold';
}

.link-button:hover {
  color: #92917d; /* 鼠标悬停时的颜色 */
  transform: scale(1.1); /* 稍微放大 */
  margin-top: -10px; /* 向上移动 */
}

.modal {
  display: none;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0,0,0);
  background-color: rgba(0,0,0,0.4);
  padding-top: 60px;
}

.modal.show {
  display: block;
}

.modal-content {
  background-color: #2D3C34;
  margin: 5% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  max-height: 70vh;
  overflow-y: auto;
  font-family: 'AP Pro Medium';
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

@media (max-width: 540px) {
  .footer-container {
    flex-direction: column;
  }

  .footer-section {
    border-bottom: 2px solid #FFFBB3;
  }
  .footer-section ul {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Create two columns */
    grid-auto-rows: minmax(50px, auto); /* Set minimum row height to 50px */
    gap: 10px; /* Space between grid items */
    justify-content: center; /* Center the grid horizontally */
    align-items: center; /* Center grid items vertically */
  }

  .contact-divider {
    display: none; /* Hide the contact divider */
  }

  .contact-section {
    margin: 25px 20px;
  }

  .footer-rights {
    flex-direction: column;
    flex-wrap: wrap;
    align-content: center;
  } 

.link-button {
    margin-left: -32%;
    margin-top: 3%;
 }
}

