/* Golf.css */
.course-content-container {
    background-color: #FFFCF9;
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
}

.coursesTitle h1 {
    text-align: center;
    margin-bottom: 20px;
    font-family: 'GTSD Bold';
    font-size: 120px;
    color: #2E2E2E;
}

.course-content {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding-top: 5%;
}

.course-image-container {
    position: relative;
    width: 50%;
    height: auto;
    padding-top: 20%;
}

.course-image {
    position: absolute;
    width: 100%;
    height: auto;
    object-fit: cover;
}

.course-image:nth-child(1) {
    z-index: 3;
    top: 0;
    left: 0;
    padding-top: 50%;
}

.course-image:nth-child(2) {
    z-index: 2;
    top: 20px;
    left: 20px;
}

.course-image:nth-child(3) {
    z-index: 1;
    top: 40px;
    left: -40px;
    padding-top: 80%;
}

.coursesContent {
    width: 50%;
    padding-left: 20px;
}

.coursesContent p {
    font-size: 18px;
    font-family: 'AP Pro Medium';
    color: #2E2E2E;
    text-align: left;
    margin-bottom: 20px;
    line-height: 1.6;
    padding-left: 20%;
}

.coursesContent h2 {
    font-size: 24px;
    margin-top: 40px;
    padding-left: 20%;
    color:#638B74;
}

.coursesContent ul {
    list-style-type: none;
    padding: 0;
    padding-left: 20%;
}

.coursesContent li {
    font-size: 18px;
    font-family: 'AP Pro Medium';
    color: #2E2E2E;
    margin-bottom: 10px;
    line-height: 1.6;
}

  .CourseOverviewLink {
    font-family: "AP Pro Light";
    display: flex;
    padding-left: 43%;
    padding-top: 5%;
    color: #638B74;
  }

  .CourseOverviewArrow {
    padding-left: 10px;
    padding-top: 2%;
    cursor: pointer;
  }

  @media (max-width: 2560px) {
    .course-content {
        width: 150%;
    }

    .course-image-container {
        width: 33%;
        left: -13%;
    }

    .coursesContent {
        padding-left: 100px;
    }

    .coursesContent p, .coursesContent li {
        font-size: 20px;
    }

    .coursesContent h2 {
        font-size: 26px;
    }

    .CourseOverviewLink {
        font-size: large;
    }

    .CourseOverviewArrow {
        padding-top: 2.5%;
    }
  }

  @media (max-width: 2460px) {
    .course-content {
        width: 145%;
    }

    .course-image-container {
        width: 32%;
        left: -12%;
    }

    .coursesContent {
        padding-left: 95px;
    }

    .coursesContent p, .coursesContent li {
        font-size: 19.5px;
    }

    .coursesContent h2 {
        font-size: 25.5px;
    }

    .CourseOverviewLink {
        font-size: large;
    }

    .CourseOverviewArrow {
        padding-top: 2.4%;
    }
}   

@media (max-width: 2360px) {
    .course-content {
        width: 140%;
    }

    .course-image-container {
        width: 31%;
        left: -11%;
    }

    .coursesContent {
        padding-left: 90px;
    }

    .coursesContent p, .coursesContent li {
        font-size: 19px;
    }

    .coursesContent h2 {
        font-size: 25px;
    }

    .CourseOverviewLink {
        font-size: large;
    }

    .CourseOverviewArrow {
        padding-top: 2.3%;
    }
}

@media (max-width: 2260px) {
    .course-image-container {
        width: 30%;
        left: -10%;
    }

    .coursesContent {
        padding-left: 85px;
    }

    .coursesContent p, .coursesContent li {
        font-size: 18.5px;
    }

    .coursesContent h2 {
        font-size: 24.5px;
    }

    .CourseOverviewLink {
        font-size: large;
    }

    .CourseOverviewArrow {
        padding-top: 2.2%;
    }
}

@media (max-width: 2160px) {
    .course-content {
        width: 130%;
    }

    .course-image-container {
        width: 29%;
        left: -3%;
    }

    .coursesContent {
        padding-left: 190px;
    }

    .coursesContent p, .coursesContent li {
        font-size: 18px;
    }

    .coursesContent h2 {
        font-size: 24px;
    }

    .CourseOverviewLink {
        font-size: initial;
    }

    .CourseOverviewArrow {
        padding-top: 2.1%;
    }
}

@media (max-width: 2060px) {
    .course-content {
        width: 125%;
    }

    .course-image-container {
        width: 28%;
        left: 0%;
    }

    .coursesContent {
        padding-left: 215px;
    }

    .coursesContent p, .coursesContent li {
        font-size: 17.5px;
    }

    .coursesContent h2 {
        font-size: 23.5px;
    }

    .CourseOverviewLink {
        font-size: initial;
    }

    .CourseOverviewArrow {
        padding-top: 2%;
    }
}

@media (max-width: 1920px) {
    .course-content {
        width: 100%;
    }

    .course-image-container {
        width: 50%;
        left: 0;
    }

    .coursesContent {
        padding-left: 20px;
    }

    .coursesContent p, .coursesContent li {
        font-size: 18px;
    }

    .coursesContent h2 {
        font-size: 24px;
    }

    .CourseOverviewLink {
        font-size: initial;
    }

    .CourseOverviewArrow {
        padding-top: 2%;
    }
}

@media (max-width: 1400px) {
    .coursesTitle h1 {
        font-size: 100px;
    }

    .course-content-container {
        max-width: 1000px;
    }

    .coursesContent p, .coursesContent li {
        font-size: 16px;
    }

    .coursesContent h2 {
        font-size: 24px;
    }
}

@media (max-width: 1300px) {
    .CourseOverviewArrow {
        padding-top: 2.3%;
    }
}

@media (max-width: 1200px) {
    .course-content-container {
        padding-left: 80px;
    }

    .course-content {
        width: 95%;
    }

    .course-image {
        width: 90%;
    }

    .coursesContent p, .coursesContent li {
        font-size: 14px;
    }

    .coursesContent h2 {
        font-size: 22px;
    }

    .CourseOverviewLink {
        padding-left: 39%;
    }
}

@media (max-width: 1000px) {
    .coursesTitle h1 {
        font-size: 80px;
    }

    .course-content {
        width: 90%;
    }

    .course-image {
        width: 80%;
    }

    .coursesContent p, .coursesContent li {
        font-size: 12px;
    }

    .coursesContent h2 {
        font-size: 20px;
    }

    .course-image:nth-child(1) {
        z-index: 3;
        top: -30px;
        left: 45px;
        padding-top: 50%;
    }

    .course-image:nth-child(2) {
        z-index: 2;
        top: 20px;
        left: 60px;
    }

    .course-image:nth-child(3) {
        z-index: 1;
        top: -5px;
        left: 20px;
        padding-top: 80%;
    }

    .CourseOverviewLink {
        padding-left: 37%;
    }
        
}

@media (max-width: 900px) {
    .course-content-container {
        padding-left: 20px;
        margin-left: 7%;
    }

    .coursesTitle h1 {
        margin-right: 5%;
        font-size: 70px;
    }

    .coursesContent p, .coursesContent li {
        font-size: 11px;
    }

    .coursesContent h2 {
        font-size: 19px;
    }

    .CourseOverviewLink {
        padding-left: 35%;
    }

    .CourseOverviewArrow {
        padding-top: 2.8%;
    }
}

@media (max-width: 800px) {
    .CourseOverviewLink {
        padding-left: 33%;
    }
    
    .CourseOverviewArrow {
        padding-top: 3.1%;
    }      
}

@media (max-width: 768px) {
    .coursesTitle h1 {
        font-size: 60px;
    }
    
    .CourseOverviewArrow {
        padding-top: 2.1%;
    } 

    .coursesContent p, .coursesContent li {
        font-size: 10px;
    }

    .coursesContent h2 {
        font-size: 18px;
    }
}

@media (max-width: 600px) {
    .coursesTitle h1 {
        font-size: 50px;
        margin-bottom: 10%;
    }

    .course-content {
        flex-direction: column;
    }

    .course-image-container {
        left: 75px;
    }

    .course-image:nth-child(1) {
        width:110%;
    }

    .course-image:nth-child(2) {
        top: 20px;
        left: 165px;
    }

    .course-image:nth-child(3) {
        top: -215px;
        left: -20px;
        width: 100%;
        height: 200%;
    }

    .coursesContent {
        margin-top: 40%;
        width: 115%;
        margin-left: -20%;
    }

    .coursesContent p, .coursesContent li {
        font-size: 12px;
    }

    .coursesContent h2 {
        font-size: 20px;
    }

    .CourseOverviewLink {
        padding-left: 31%;
    }
    
    .CourseOverviewArrow {
        padding-top: 2.8%;
    }  
} 

@media (max-width: 550px) {
    .course-image-container {
        left: 70px;
    }

    .course-image:nth-child(2) {
        left: 145px;
    }

    .course-image:nth-child(3) {
        top: -195px;
    }
}

@media (max-width: 500px) {
    .course-image-container {
        left: 67px;
    }

    .course-image:nth-child(2) {
        left: 125px;
    }

    .course-image:nth-child(3) {
        top: -175px;
    }
}

@media (max-width: 450px) {
    .coursesTitle h1 {
        font-size: 40px;
    }

    .course-image-container {
        left: 67px;
    }

    .course-image:nth-child(2) {
        left: 115px;
    }

    .course-image:nth-child(3) {
        top: -155px;
    }
}

@media (max-width: 400px) {
    .course-image-container {
        left: 62px;
    }

    .course-image:nth-child(1) {
        top: -20px;
        left: 30px;
    }

    .course-image:nth-child(2) {
        left: 105px;
    }

    .course-image:nth-child(3) {
        top: -135px;
    }
}

@media (max-width: 375px) {
    .course-image-container {
        left: 58px;
    }

    .course-image:nth-child(1) {
        top: -15px;
        left: 15px;
    }

    .course-image:nth-child(2) {
        left: 95px;
    }

    .course-image:nth-child(3) {
        top: -125px;
    }
}

@media (max-width: 340px) {
    .course-image-container {
        left: 58px;
    }

    .course-image:nth-child(2) {
        left: 85px;
    }

    .course-image:nth-child(3) {
        top: -115px;
    }
}

@media (max-width: 340px) {
    .course-image:nth-child(2) {
        left: 75px;
    }

    .course-image:nth-child(3) {
        top: -95px;
    }
}