.booking-form {
    flex: 1;
    max-width: 600px; /* 表单的最大宽度，根据需要调整 */
    margin-left: 3%;
    margin-top: 3%;
    padding-bottom: 3%;
    padding-right: 3%;
}
.booking-container {
    display: flex;
    justify-content: center;
    align-items: flex-start; /* or 'center' depending on your layout */
    padding-top: 80px; /* Adjust this based on the height of your Header */
    background-color:#F3E8DD ;
  }
  
/* Swiper Gallery Styles */
.booking-container{
  width: 100%;
  margin-top: 20px;
}

.booking-image {
  width: 60%;
  margin-top: 20px;
  overflow-x: hidden;
  height: 1100px; /* Adjust if necessary */
  overflow-y: hidden;
  display: flex;
  justify-content: center; /* Centers content horizontally */
  align-items: center; /* Centers content vertically, if needed */
}

.swiper-slide {
  display: flex;
  align-items: center;
  justify-content: center;
}

.swiper-slide img {
  max-width: 100%;
  height: auto;
  margin: auto; /* Centers the image in the available space */
}

@media (max-width: 1024px) {
  .booking-image {
    display: none;
  }
}

@media (max-width: 600px) {
  .booking-form {
    margin-top: 0%;
  }
}
