.golf-page {
  padding-top: 0px; 
}

.golf-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
}

.grid-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center; /* Ensure text is centered horizontally */
}

.Nursery img, .Valley-course-image img, .Homestead img {
  background-size: cover;
  background-position: center;
}

.Nursery-course-description, .Valley-course-description, .Homestead-course-description {
  background: #F3E8DD;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px; /* Add some spacing */
}

.grid-item img {
  width: 100%;
  height: 100%; /* Make the image fill the height */
  object-fit: cover; /* Cover the entire grid cell, potentially cropping the image */
}

.grid-item h2 {
  margin-top: 10%;
  margin-bottom: 0.5em;
  font-family: 'GTSD Bold';
  font-size: 40px;
}

.grid-item p {
  flex-grow: 1; /* Ensure the description takes all available space */
  font-family: 'AP Pro Medium';
  color: #2E2E2E;
  padding-left: 60px;
  padding-right: 60px;
  font-size: large;
}

.course-description {
  text-align: center;
  margin-bottom: 20px;
  padding: 20px; /* Add some padding */
}

.course-description h2 {
  margin-bottom: 0.5em;
  font-family: 'GTSD Bold';
  font-size: 40px;
}

.course-description p {
  font-family: 'AP Pro Medium';
  color: #2E2E2E;
  padding-left: 60px;
  padding-right: 60px;
  font-size: large;
}

.course-image img {
  width: 100%;
  height: auto; /* Make the image fill the height */
  object-fit: cover;
}

.button {
    display: inline-block;
    padding: 10px 20px;
    font-size: 16px;
    text-align: center;
    text-decoration: none;
    background-color: #2D3C34; /* Adjust color as needed */
    color: white;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.button:hover {
    background-color: #2D3C34; /* Adjust hover color as needed */
}

.full-width-button {
    width: 100%;
    text-align: center;
}


/* Responsive design: stack layout on smaller screens */
@media (max-width: 768px) {
  .golf-grid {
    display: flex;
    flex-direction: column;
  }

  /* Assigning order to each item */
  .Nursery {
    order: 1; /* First in the sequence */
  }

  .Nursery-course-description {
    order: 2; /* Second in the sequence */
  }

  .Valley-course-image {
    order: 3; /* Third in the sequence */
  }

  .Valley-course-description {
    order: 4; /* Fourth in the sequence */
  }

  .Homestead {
    order: 5; /* Fifth in the sequence */
  }

  .Homestead-course-description {
    order: 6; /* Sixth in the sequence */
  }

  .grid-item {
    text-align: left; 
  }
}

.button {
  padding: 10px 20px;
  border: 2px solid transparent;
  background-color: #2D3C34;
  color: #FFFBB3;
  font-size: 16px;
  border-radius: 20px;
  outline: none;
  cursor: pointer;
  font-family: 'AP Pro Medium';
}

.button:hover {
  border-color: #FFFBB3; /* Border color on hover */
}

.button:active {
  background-color: #fff; /* Background color on click */
}
  