    /* src/pages/HolePage.css */
    .images {
    display: flex;
    gap: 20px;
    margin-top: 20px;
    }

    .images img {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
    }

    .navigation-links {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    }

    .navigation-link {
    color: #007bff;
    text-decoration: none;
    }

    .navigation-link:hover {
    text-decoration: underline;
    }

.hole-page-container h1{
    text-align: center;
    margin-bottom: 20px;
    font-family: 'GTSD Bold';
    font-size: 32px;
    color: #638B74;
    padding-top: 2%;
}

.hole-page-container p{
    text-align: left;
    margin-bottom: 20px;
    font-family: 'AP pro light';
    font-size: 20px;
    color: #2e2e2e;
    padding-top: 2%;
}

.hole-page-container{
    background-color: #FFFCF9;
}