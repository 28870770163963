/* Common properties for all bubbles */
.parallax-banner1 {
    position: relative;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    border-radius: 50%; /* Circular shape */
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: white;
    margin: auto;
    overflow: hidden;
    opacity: 20%;
    

}

/* Specific properties for different sizes */
.parallax-banner1.small {
    height: 200px;
    width: 200px;
    left: 9%;
}

.parallax-banner1.medium {
    height: 300px;
    width: 300px;
}

.parallax-banner1.large {
    height: 800px;
    width: 800px;
    left: 30%;
    z-index: 1;
    position: relative;
}

/* Adjust the text margins for each bubble */
.parallax-banner1 h1, .parallax-banner1 p {
    margin: 0;
}