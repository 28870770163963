/* FunctionForm.css */
.function-styled-table {
    border-collapse: collapse;
    margin-bottom: 20px;
    width: 90%;
}

.function-styled-table, .function-styled-table td {
    border: 1px solid #ddd;
    text-align: left;
}

.function-styled-table th {
    background-color: #f2f2f2;
}

.functionInnerTable {
    font-family: 'AP Pro Medium';
    color: #638B74;
    padding-right: 10%;
}


