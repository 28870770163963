@font-face {
    font-family: 'GSDL Italic'; 
    src: url('../assets/fonts/GT-Super/GT-Super-Display-Light-Italic-Trial.otf') format('opentype');
}

@font-face {
    font-family: 'GSDM Italic'; 
    src: url('../assets/fonts/GT-Super/GT-Super-Display-Medium-Italic-Trial.otf') format('opentype');
}

@font-face {
    font-family: 'GSDL regular'; 
    src: url('../assets/fonts/GT-Super/GT-Super-Display-Regular-Trial.otf') format('opentype');
}

@font-face {
    font-family: 'AP Pro Light'; 
    src: url('../assets/fonts/Apercu/Apercu Pro Light.otf') format('opentype');
  }
/*
.visitor-info-container {
    display: flex;
    background-color: #2D3C34;
    color: #FFF; 
    margin-top: 100px;
    min-width: 100%;
    justify-content: space-between;

    flex-wrap: nowrap;
}
*/

/*
.visitor-info-container {
    display: flex;
    flex-wrap: wrap; 
    justify-content: space-between;
    background-color: #2D3C34;
    color: #FFF;
    margin-top: 100px;
    width: 100%;
}

.info-section {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    width: 43%;
    align-items: flex-start;
    flex: 1; 
    max-width: 50%;
}

.imageClubInfo-section {
    flex: 1; 
    max-width: 50%; 
}
*/

.visitor-info-container {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: stretch; 
    background-color: #2D3C34;
    color: #FFF;
    margin-top: 100px;
    width: 100%;
}

.info-section {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    flex: 1; 
    max-width: 50%;
    align-items: flex-start;
}

.imageClubInfo-section {
    display: flex;
    flex: 1; 
    max-width: 50%; 
    justify-content: center; 
    align-items: center; 
}

.imageClubInfo-section img {
    width: 100%; 
    height: 100%; 
    object-fit: cover; 
}

.welcoming-info{
    font-family: 'AP Pro Medium';
    color: #FFF;
    text-align: justify;
    line-height: 150%;
    margin-top: 6%;
    margin-left: 10%;
    font-size: 12px;
}

.p1, .p2{
    font-family: 'AP Pro Medium';
    color: #FFF;
    text-align: justify;
    line-height: 150%;
    box-sizing: border-box;
    font-size: 12px;
}

.p1 {
    margin-left: 40%;
    margin-top: 20%;
}

.p2 {
    margin-left: 6%;
    margin-top: 3%;
}

.p3{
    font-family: 'AP Pro Medium';
    color: #FFF;
    text-align: justify;
    line-height: 150%;
    margin-top: 18%;
    margin-left: 10%;
    font-size: 12px;
}

.info-section h1{
    font-family: 'GTSD Bold';
    color: #FFF;;
    margin-top: 15%;
    font-size: 100px;
    margin-left: 18%;

}

.info-section h2{
    font-family: 'GTSD Italic Bold';
    color: #FFFBB3;
    margin-top: -7%;
    font-size: 100px;
    margin-left: 18%;
}



.CourseOverview {
    background-color: #FFFCF8;
    padding-top: 10px;
    min-height: 1600px;
}

.parallax-text-visitor  {
    font-family: "GSDL Italic";
    font-size: 200px;
    text-align: center;
    flex-wrap: nowrap; /* 禁止换行 */
    color: #2e2e2e; 
    margin-top: 10%;
    white-space: nowrap;
    transform: rotate(0deg);
  }

.Courseinfo{
    display: flex;
    margin-top: 10%;
}

.Links{
    font-family: "GSDL regular";
    color:#598971; 
    font-size: 100px;
    margin-top: 0%;
    margin-left: 11%;
}

.CourceImage{
    width: 40%;
    height: 30%;
    text-align: center;
    margin-left: 6%;

}

.Championship-name {
    font-family: "AP Pro Medium";
    color: #2e2e2e;
    margin-left: 3%;
}

.mt-8 {
    margin-top: 3%;
    display: flex;
}

.CourceOverviewTitle{
    font-family: "AP Pro Medium";
    color: #2e2e2e;
    font-size: 15px;
    margin-left: 315%;
    width: 100%;
}

.CourceOverviewText{
    font-family: "GTSD Regular";
    color: #2e2e2e;
    font-size: 30px;
    margin-left: 42%;
    width: 30%;
    margin-top: 1.2%;
    line-height: 1.5;
}

.GolfHeaderImage {
    overflow: hidden;
    width: 100%;
    height: 100%;
    object-fit: cover;
    background-position: center; 
    z-index: 999;
}


.CafeInfo {
    background-color: #FFFCF8;
    min-height: 1000px;
    padding: none;
}


.CafeName{
    margin-top: -4%;
    display: flex;
    margin-left: 5%;
}
.Barname {
    font-family: "GTSD Regular";
    color: #2e2e2e;
    font-size: 80px;
    margin-left: 30%;
    padding-top: 10%;
    line-height: 1.5;
    align-items: center;
}

.Dining-room {
    font-family: "GSDM Italic";
    color: #608971;
    font-size: 80px;
    padding-top: 10%;
    margin-top: 11%;
    margin-left: 5%;
}

.OurFaclities {
    font-family: "AP Pro Medium";
    color: #2e2e2e;
    font-size: 15px;
    padding-top: 10%;
    margin-top: 12%;
    margin-left: -28%;
    line-height: 1;
}

.CafeContentContainer {
    display: flex;
    min-height: 900px;
}

.CafeContentInfo1{
    font-family: "AP Pro Light";
    color: #2e2e2e;
    font-size: 16px;
    line-height: 1.5;
    width : 15%;
    margin-left: 7%;
    margin-top: 15%;
}

.CafeContentInfo2{
    font-family: "AP Pro Light";
    color: #2e2e2e;
    font-size: 16px;
    line-height: 1.5;
    width : 15%;
    margin-top: 23%;
    margin-left: -15%;
}

.rotated-frame {
    width: 600px;
    height: 650px;
    transform: rotate(10deg);
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 15%;
    margin-top: 3%;
  }
  
  .rotated-content {
    transform: rotate(-10deg);
  }
  
  .rotated-content img {
    width: auto;
    height: auto;
  }
  
  .CafeBookingLink {
    margin-top: 35%;
    margin-left: 5%;
    font-family: "AP Pro Light";
    display: flex;
  }

  .BookingArrow {
    margin-top: 6%;
    padding-left: 10px;
    cursor: pointer;
  }
  
  a {
    color: inherit; /* This will make the color the same as the parent element */
    text-decoration: none; /* This removes the underline */
  }
  
  /* You can also specify the color explicitly */
  a {
    color: black; /* Or any other color */
    text-decoration: none; /* This removes the underline */
  }
  
  /* To target anchor tags only when they are in a normal, hover, active, or visited state: */
  a:link, a:visited {
    color: inherit; /* Inherits color from parent element */
    text-decoration: none;
  }
  
  a:hover, a:active {
    color: inherit; /* Inherits color from parent element */
    text-decoration: underline; /* Or any other styling for hover/active states */
  }

  @media (max-width: 2560px) {
    .welcoming-info {
        font-size: 16px;
    }

    .p1, .p2, .p3{
        font-size: 16px;
    }

    .p1 {
        margin-left: 66%;
        padding-right: 5%;
        text-align: right;
    }

    .p2 {
        margin-left: 41%;
        padding-right: 5%;
        text-align: right;
    }

    .Links {
        margin-left: 15%;
    }

    .Championship-name {
        font-size: x-large;
    }

    .CourceOverviewTitle {
        font-size: 19px;
        margin-left: 354%;
    }

    .CourceOverviewText {
        font-size: 35px;
        margin-left: 59%;
        margin-top: 0.5%;
    }

    .Barname {
        font-size: 90px;
    }

    .OurFaclities {
        font-size: 20px;
        margin-top: 8%;
        margin-left: -19%;
    }

    .Dining-room {
        font-size: 90px;
        margin-top: 8%;
        margin-left: 3%;
    }

    .rotated-frame {
        width: 650px;
        height: 700px;
    }

    .CafeContentInfo1 {
        font-size: 20px;
        margin-top: 7%;
    }

    .CafeContentInfo2 {
        font-size: 20px;
        margin-top: 13%;
    }

    .CafeBookingLink {
        margin-top: 16%;
        margin-left: 12%;
        font-size: 20px;
    }

    .BookingArrow {
        margin-top: 8%;
    }
}

@media (max-width: 2400px) {
    .welcoming-info {
        font-size: 14px;
    }

    .p1, .p2, .p3{
        font-size: 14px;
    }

    .p1 {
        margin-left: 59%;
    }

    .p2 {
        margin-left: 31%;
    }
}
/*
@media (max-width: 2560px) {
    .welcoming-info {
        font-size: 16px;
    }

    .p1, .p2, .p3{
        font-size: 16px;
    }

    .p1 {
        margin-left: 64%;
        padding-right: 5%;
        text-align: right;
    }

    .p2 {
        margin-left: 41%;
        padding-right: 5%;
        text-align: right;
    }
}

@media (max-width: 2560px) {
    .welcoming-info {
        font-size: 14px;
    }

    .p1, .p2, .p3{
        font-size: 14px;
    }

    .p1 {
        margin-left: 59%;
    }

    .p2 {
        margin-left: 31%;
    }

    .Links {
        margin-left: 15%;
    }

    .Championship-name {
        font-size: x-large;
    }

    .CourceOverviewTitle {
        font-size: 19px;
        margin-left: 354%;
    }

    .CourceOverviewText {
        font-size: 35px;
        margin-left: 59%;
        margin-top: 0.5%;
    }

    .Barname {
        font-size: 90px;
    }

    .OurFaclities {
        font-size: 20px;
        margin-top: 8%;
        margin-left: -19%;
    }

    .Dining-room {
        font-size: 90px;
        margin-top: 8%;
        margin-left: 3%;
    }

    .rotated-frame {
        width: 650px;
        height: 700px;
    }

    .CafeContentInfo1 {
        font-size: 20px;
        margin-top: 7%;
    }

    .CafeContentInfo2 {
        font-size: 20px;
        margin-top: 13%;
    }

    .CafeBookingLink {
        margin-top: 16%;
        margin-left: 12%;
        font-size: 20px;
    }

    .BookingArrow {
        margin-top: 8%;
    }
}
*/
@media (max-width: 2300px) {
    .OurFaclities, .Dining-room {
        margin-top: 9%;
    }

    .OurFaclities {
        margin-left: -21%;
    }
}

@media (max-width: 2200px) {
    .Links {
        margin-left: 14%;
    }

    .CourceOverviewTitle {
        margin-left: 295%;
    }

    .CafeContentInfo2 {
        margin-top: 15%;
    }
}

@media (max-width: 2100px) {
    .OurFaclities, .Dining-room {
        margin-top: 10%;
    }

    .OurFaclities {
        margin-left: -23%;
    }

    .CafeBookingLink {
        margin-top: 30%;
    }
}

@media (max-width: 2000px) {
    .Links {
        margin-left: 13%;
    }
 
    .Championship-name {
        font-size: large;
    }
    
    .CourceOverviewTitle {
        margin-left: 260%;
    }

    .CourceOverviewText {
        font-size: 30px;
    }

    .CafeContentInfo2 {
        margin-top: 17%;
    }
}

@media (max-width: 1920px) {
    .Links {
        margin-left: 12.5%;
    }
    
    .CourceOverviewTitle {
        margin-left: 250%;
    }

    .Barname {
        font-size: 80px;
    }

    .OurFaclities {
        font-size: 15px;
        margin-left: -22%;
    }

    .Dining-room {
        font-size: 80px;
    }

    .rotated-frame {
        width: 600px;
        height: 750px;
    }

    .CafeBookingLink {
        font-size: 15px;
        margin-top: 32%;
        margin-left: 7%;
    }

    .BookingArrow {
        margin-top: 5%;
    }

}

@media (max-width: 1800px) {
    .Links {
        margin-left: 12%;
    }
    
    .CourceOverviewTitle {
        margin-left: 230%;
    }

    .CafeContentInfo2 {
        margin-top: 18%;
    }
}

@media (max-width: 1700px) {
    .OurFaclities {
        margin-left: -25%;
    }

    .OurFaclities, .Dining-room {
        margin-top: 11%;
    }

    .CafeBookingLink {
        margin-top: 36%;
        margin-left: 5%;
    }

    .BookingArrow {
        margin-top: 6%;
    }
}

@media (max-width: 1600px) {
    .Links {
        margin-left: 10%;
    }
    
    .CourceOverviewTitle {
        margin-left: 190%;
    }

    .CourceOverviewText {
        margin-left: 57%;
    }

    .CafeContentInfo2 {
        margin-top: 20%;
    }
}

@media (max-width: 1500px) {
    .info-section h1, .info-section h2 {
        margin-left: 15%;
    }

    .Links {
        font-size: 95px;
    }

    .CourceOverviewTitle {
        margin-left: 175%;
    }

    .CafeContentInfo2 {
        margin-top: 24%;
    }

    .OurFaclities, .Dining-room {
        margin-top: 12%;
    }

    .OurFaclities {
        margin-left: -28%;
    }

    .CafeBookingLink {
        margin-top: 40%;
        margin-left: 3%;
    }

    .BookingArrow {
        margin-top: 5%;
    }
}

  @media (max-width: 1400px) {
    .welcoming-info {
        font-size: 12px;
    }

    .p1, .p2, .p3{
        font-size: 12px;
    }

    .info-section h1, .info-section h2 {
        margin-left: 12%;
    }

    .Links {
        margin-left: 9%;
    }

    .CourceOverviewTitle {
        margin-left: 205%;
        font-size: medium;
    }

    .CourceOverviewText {
        font-size: 28px;
        font-size: x-large;
    }

    .CafeName {
        width: 110%;
        margin-left: -5%;
    }

    .OurFaclities, .Dining-room {
        margin-top: 12%;
    }

    .OurFaclities {
        margin-left: -26%;
    }

    .CafeContentInfo1, .CafeContentInfo2 {
        font-size: 18px;
    }

    .rotated-frame {
        width: 550px;
        height: 700px;
        margin-left: 12%;
    }

    .CafeBookingLink {
        margin-top: 40%;
        margin-left: 3%;
    }

    .BookingArrow {
        margin-top: 5%;
    }
    
}

@media (max-width: 1300px) {
    .info-section h1, .info-section h2 {
        font-size: 80px;
    }

    .CourceOverviewTitle {
        margin-left: 195%;
        width: 150%;
    }

    .CourceOverviewText {
        font-size: 26px;
        margin-left: 55%;
    }

    .CafeContentInfo2 {
        margin-top: 26%;
    }
    
    .CafeBookingLink {
        margin-top: 44%;
        margin-left: 2%;
    }

    .BookingArrow {
        margin-top: 6%;
    }
}

@media (max-width: 1200px) {
    .Links {
        font-size: 80px;
    }

    .CourceOverviewTitle {
        margin-left: 158%;
    }

    .CafeName {
        margin-left: -9%;
    }

    .OurFaclities, .Dining-room {
        margin-top: 14%;
    }

    .OurFaclities {
        margin-left: -30%;
    }

    .CafeContentInfo2 {
        margin-top: 30%;
    }

    .rotated-frame {
        width: 500px;
        height: 650px;
    }

}

@media (max-width: 1100px) {
    .info-section h1, .info-section h2 {
        font-size: 70px;
    }

    .CourceOverviewTitle {
        margin-left: 150%;
    }

    .CourceOverviewText {
        font-size: 25px;
        font-size: x-large;
    }

    .CafeName {
        margin-left: -10%;
    }

    .Barname, .Dining-room {
        font-size: 70px;
    }

    .OurFaclities {
        font-size: 13px;
        margin-left: -29%;
    }

    .CafeContentInfo1, .CafeContentInfo2 {
        font-size: 16px;
    }

    .CafeContentInfo2 {
        margin-top: 32%;
    }

    .rotated-frame {
        width: 450px;
        height: 600px;
    }

    .CafeBookingLink {
        margin-top: 49%;
        margin-left: 1%;
    }

}

@media (max-width: 1028px) {
    .CafeBookingLink {
        margin-top: 53%;
        margin-left: 0%;
    }

}

  @media (max-width: 1024px) {
    .CafeName {
        margin-left: -12%;
    }

    .OurFaclities {
        margin-left: -31%;
    }

    .Links {
        font-size: 60px;
    }

    .mt-8 {
        margin-left: -5%;
    }

    .CafeBookingLink {
        font-size: 13px;
    }

    .BookingArrow {
        margin-top: 5%;
    }

}

@media (max-width: 1000px) {
    .info-section h1, .info-section h2 {
        font-size: 65px;
    }

    .Links {
        font-size: 55px;
        margin-left: 13%;
    }

    .CourceOverviewTitle {
        font-size: small;
        margin-left: 215%;
    }

    .CafeContentInfo1 {
        margin-top: -2%;
    }

    .CafeContentInfo2 {
        margin-top: 23%;
    }

    .rotated-frame {
        width: 400px;
        height: 550px;
    }
}

@media (max-width: 968px) {
    .info-section h1, .info-section h2 {
        font-size: 65px;
    }
}

@media (max-width: 912px) {
    .CafeContentContainer {
        display: flex;
        min-height: 900px;
        flex-direction: column;
    }

    .Barname, .Dining-room {
        font-size: 60px;
        margin-bottom: 12%;
    }

    .CafeContentInfo1 {
        width: 30%;
    }

    .CafeContentInfo2 {
        width: 30%;
        margin-top: -10%;
        margin-left: 60%;
    }

    .rotated-frame {
        width: 635px;
    }
    
    .CafeBookingLink {
        margin-top: 5%;
        padding-left: 5%;
    }

    .CafeBookingLink p {
        font-size: x-large;
    }

    .BookingArrow {
        margin-top: 3%;
    }

    .CourceOverviewTitle {
        margin-left: 230%;
    }
}

@media (max-width: 900px) {
    .Links {
        margin-left: 12%;
    }

    .CourceOverviewTitle {
        margin-left: 190%;
    }
}

@media (max-width: 870px) {
    .info-section h1, .info-section h2 {
        font-size: 55px;
    }

    .CafeName {
        margin-left: -9%;
    }

    .CourceOverviewTitle {
        margin-left: 210%;
    }
}

@media (max-width: 820px) {
    .CafeName {
        margin-left: -15%;
    }
}

@media (max-width: 800px) {
    .Links {
        margin-left: 10%;
        font-size: 50px;
    }

    .Championship-name {
        font-size: medium;
    }

    .CourceOverviewTitle {
        margin-left: 145%;
    }

    .CourceOverviewText {
        font-size: 25px;
        font-size: large;
    }

    .CafeName {
        margin-left: -17%;
    }

    .OurFaclities {
        margin-left: -34%;
    }
}

@media (max-width: 768px) {
    .visitor-info-container {
        display: flex;
        flex-direction: column;
    }

    .welcoming-info {
        margin-top: 20%;
        margin-left: 10%;
        font-size: 14px;
    }

    .info-section h1, .info-section h2 {
        font-weight: bold; 
        color: transparent;
        background-image: url('../assets/images/VisitorInfo/VisitorInfo1.png'); 
        background-size: cover;
        background-clip: text;
        -webkit-background-clip: text; 
        -webkit-text-fill-color: transparent; 
        display: inline-block; 
    }

    .info-section h1 {
        margin-left: 35%;
        margin-top: 40%;
        font-size: 100px;
    }

    .info-section h2 {
        font-size: 100px;
        margin-top: -20%;
        margin-left: 36%;
    }

    .p1 {
        margin-left: 150%;
        margin-top: -90%;
        font-size: 14px;
        width: 51%;
    }

    .p2 {
        margin-left: 10%;
        margin-top: 95%;
        width: 196%;
        font-size: 14px;
        padding-right: 35px;
    }

    .p3 {
        margin-top: 18%;
        margin-left: 10%;
        font-size: 14px;
        width: 153%;
    }

    .imageClubInfo-section {
        display: none; 
    }

    .CourseOverview {
        min-height: 1300px;
    }

    .Links {
        font-size: 40px;
    }

    .CourceOverviewTitle {
        font-size: 12px;
    }

    .CourceOverviewText {
        font-size: 18px;
    }

    .CafeContentContainer {
        display: flex;
        min-height: 900px;
        flex-direction: column;
    }

    .CafeName {
        margin-left: -29%;
        width: 170%;
    }

    .Barname, .Dining-room {
        font-size: 50px;
    }

    .OurFaclities, .Dining-room{
        margin-top: 10%;
    }

    .OurFaclities {
        margin-left: -20%;
    }

    .CafeContentInfo1 {
        width: 55%;
        margin-left: 7%;
    }

    .CafeContentInfo2 {
        width: 55%;
        margin-top: 5%;
        margin-left: 43%;
    }

    .rotated-frame {
        width: 120%;
        margin-left: -10%;
        margin-top: 3%;
    }

    .CafeBookingLink {
        margin-top: 10%;
    }

    .CafeBookingLink p {
        font-size: x-large;
    }

    .BookingArrow {
        margin-top: 4%;
    }
}

@media (max-width: 700px) {
    .info-section h1 {
        font-size: 80px;
    }

    .info-section h2 {
        font-size: 80px;
    }

    .mt-8 {
        margin-left: -10%;
    }

    .CourceOverviewTitle {
        margin-left: 155%;
    }
    .CafeName {
        margin-left: -33%;
    }

    .OurFaclities {
        margin-left: -22%;
    }

}

@media (max-width: 650px) {
    .CafeName {
        margin-left: -36%;
    }
}

@media (max-width: 601px) {
    .info-section h1 {
        font-size: 70px;
    }

    .info-section h2 {
        font-size: 70px;
    }

    .welcoming-info {
        font-size: 14px;
    }

    .p1 {
        font-size: 14px;
    }

    .p2 {
        font-size: 14px;
    }

    .p3 {
        font-size: 14px;
    }

    .mt-8 {
        margin-left: -15%;
    }

    .Barname {
        font-size: 45px;
    }

    .Dining-room {
        font-size: 45px;
    }

    .rotated-frame {
        width: 160%;
        margin-left: -40%;
    }
}

@media (max-width: 540px) {
    .Courseinfo {
        margin-top: 10%;
        flex-direction: column;
    }

    .CourceImage {
        width: 80%;
    }

    .Championship-name {
        margin-left: 0%;
        padding-left: 30px;
        margin-top: 5%;
        font-size: large;
    }

    .Links {
        font-size: 50px;
        margin-left: 5%;
    }

    .mt-8 {
        margin-left: 5%;
        margin-top: 12%;
    }

    .CourceOverview {
        display: flex;
        flex-direction: column;
        align-items: flex-start; 
        text-align: left; 
        padding-left: 10px; 
    }

    .CourceOverviewTitle {
        margin-left: 0;
        width: 100%;
        font-size: 14px; 
    }

    .CourceOverviewText {
        margin-left: 15%;
        margin-top: 1.5%;
        width: 50%;
        font-size: 18px; 
    }

    .Barname {
        font-size: 40px;
    }

    .Dining-room {
        font-size: 40px;
    }

    .OurFaclities p {
        font-size: x-small;
    }
}

@media (max-width: 490px) {
    .info-section h1 {
        font-size: 50px;
    }

    .info-section h2 {
        font-size: 50px;
    }

    .Barname {
        font-size: 35px;
    }

    .Dining-room {
        font-size: 35px;
    }

}

@media (max-width: 430px) {
    .welcoming-info {
        font-size: 12px;
    }

    .p1 {
        font-size: 12px;
    }

    .p2 {
        font-size: 12px;
    }

    .p3 {
        font-size: 12px;
    }

    .Links {
        font-size: 40px;
    }

    .CourceOverviewTitle {
        font-size: 11px;
    }

    .CourceOverviewText {
        margin-left: 10%;
        width: 60%;
        font-size: 15px;
        padding-right: 5%;
    }

    .Barname {
        font-size: 30px;
    }

    .Dining-room {
        font-size: 30px;
    }

}

@media (max-width: 375px) {
    .info-section h1 {
        font-size: 40px;
    }

    .info-section h2 {
        font-size: 40px;
    }

    .Barname {
        font-size: 25px;
    }

    .Dining-room {
        font-size: 25px;
    }

    .OurFaclities p {
        font-size: xx-small;
    }

    .CafeContentInfo1 {
        margin-left: 7%;
        width: 75%;
        font-size: 12px;
    }

    .CafeContentInfo2 {
        margin-left: 22%;
        width: 75%;
        font-size: 12px;
        margin-top: 5%;
    }

    .rotated-frame {
        width: 146%;
        margin-left: -22%;
        margin-top: 8%;
    }

    .CafeBookingLink p {
        font-size: large;
    }

    .BookingArrow {
        margin-top: 4%;
    }

}

@media (max-width: 280px) {
    .Barname {
        font-size: 20px;
    }

    .Dining-room {
        font-size: 20px;
    }

}