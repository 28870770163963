/* src/views/CoachProfile.css */

.coach-profile-background {
    background-color: #FFFCF9;
    min-height: 100vh;
}

.coach-profile-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: #FFFCF9;
    margin: auto;
}

.coach-image-container {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    padding-top: 2%;
}

.coach-photo {
    width: 300px;
    height: 300px;
    border-radius: 50%;
    object-fit: cover;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.coach-info {
    max-width: 800px;
    text-align: center;
}

.coach-info h2 {
    font-family: 'GTSD Bold';
    font-size: 36px;
    margin-bottom: 20px;
    color: #333;
}

.coach-info p {
    font-family: 'AP Pro Medium';
    font-size: 18px;
    color: #666;
    margin-bottom: 20px;
}

.coaching-rates {
    margin-top: 40px;
    margin-bottom: 40px;
}

.coaching-rates h3 {
    font-family: 'GTSD Bold';
    font-size: 28px;
    color: #333;
    margin-bottom: 20px;
}

.table-container {
    max-width: 600px;
    margin: auto;
}

.table-container table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
}

.table-container th,
.table-container td {
    border: 1px solid #ddd;
    padding: 12px;
    text-align: left;
    font-family: 'AP Pro Medium';
}

.table-container th {
    background-color: #f2f2f2;
    color: #333;
    font-size: 18px;
}

.table-container td {
    font-size: 16px;
    color: #666;
}

.full-width-button {
    text-align: center;
    display: block;
    margin: 20px auto;
}

.TableContainer .bold {
    font-family: 'GTSD Bold';
    font-size: 18px;
    color: #333;
}