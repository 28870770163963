@font-face {
  font-family: 'GTSD Italic Light'; 
  src: url('../assets/fonts/GT-Super/GT-Super-Display-Light-Italic-Trial.otf') format('opentype');
}

@font-face {
  font-family: 'AP Pro Medium'; 
  src: url('../assets/fonts/Apercu/Apercu Pro Regular.otf') format('opentype');
}

body, html {
  margin: 0;
  padding: 0;
  overflow-x: hidden; /* Prevent horizontal scrolling */
}

.about-us-intro {
  position: relative;
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #2D3C34;
}

.about-us-gallery {
  position: relative; 
  width: 100%; 
  height: 500px; 
}

.about-us-image, .about-us-image1 {
  width: auto; 
  height: 100%; 
  object-fit: cover; 
  position: absolute; 
}

.about-us-image {
  left: 10%; 
  top: 10%; 

}

.about-us-image1 {
  right: 10%; 
  bottom: 10%;
    padding-bottom: 2%;
}

.about-us-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.about-us-text h1 {
  color: #F1FBEC; /* 文本颜色 */
  font-family: 'GTSD Italic Light'; /* 字体 */
  width: 600px; /* 文本框的宽度 */
  height: 100px; /* 文本框的高度 */
  margin: auto; /* 水平居中 */
  display: block; /* 将元素设为块级，以便应用宽度 */
  line-height:40px; /* 设置行高与容器高度相同，使得单行文本垂直居中 */
  position: absolute; /* 绝对定位 */
  top: 63%; /* 相对于最近的定位祖先元素向下50% */
  left: 60%; /* 相对于最近的定位祖先元素向右50% */
  transform: translate(-50%, -50%); /* 使用变换偏移，使元素确切居中于其父元素 */
}


.about-us-text p{
  color: #A7B4A0; /* Choose a color that stands out on your background */
  font-family: 'AP Pro Medium';
  width: 600px; /* 文本框的宽度 */
  height: 100px; /* 文本框的高度 */
  margin: auto; /* 水平居中 */
  display: block; /* 将元素设为块级，以便应用宽度 */
  line-height: 23px; /* 设置行高与容器高度相同，使得单行文本垂直居中 */
  position: absolute; /* 绝对定位 */
  top: 83%; /* 相对于最近的定位祖先元素向下50% */
  left: 60%; /* 相对于最近的定位祖先元素向右50% */
  transform: translate(-50%, -50%); /* 使用变换偏移，使元素确切居中于其父元素 */
}



.about-us-container {
  margin: 0 auto;
  padding: 20px;
  padding-top: 100px;
  box-sizing: border-box;
  background-color: #F3E8DD;
}

.history-section {
  margin-bottom: 50px; 
}


h2, h3 {
  color: #2D3C34; 
  font-family: 'GTSD Bold', sans-serif; 
  text-align: center; 
  margin-bottom: 20px; 
}

.parallax-text {
overflow-x: hidden;
font-family: 'GTSD bold';
color: #F1FBEC; 
font-size: 60px;
margin-top: -10%;
margin-left: -65%;
white-space: nowrap; /* 防止文本换行 */
transform: rotate(-45deg); /* 倾斜文本 */   
}

.history-section p {
  font-family: 'AP Pro Medium', sans-serif; 
  color: #2E2E2E;
  text-align: justify;
  line-height: 1.6;
  margin-bottom: 20px; 
}

.timeline-container h1 {
  font-family: 'GTSD Bold', sans-serif; 
  color: #2D3C34; 
  text-align: center; 
  margin-bottom: 20px; 
}

.timeline-container {
display: flex;
flex-direction: column;
align-items: center; /* 将标题居中对齐 */
background-color: #F3E8DD;
padding: 20px; /* 添加一些内边距 */

}


.timeline-event {
width: 100%;
display: flex;
align-items: center; 
justify-content: space-between; 
position: relative;
margin-bottom: 2rem; 
}

.year {
width: 50px;
flex-shrink: 0;
text-align: center;
font-family: 'GTSD Italic Bold';
font-size: 60px;
margin-left: 10%;

}

.event-image {
width: 300px;
max-height: 300px;
flex-shrink: 0;
margin-right: 10%; 
}

.event-content {
position: absolute;
top: 50%;
right: 15%; 
transform: translate(-50%, -50%);
padding: 1rem;
font-family: 'AP Pro Medium', sans-serif;
font-size: 20px;
color: #2E2E2E;
text-align: justify;
line-height: 1.6;
max-width: 500px; 

}


@media (max-width: 2560px) {
.about-us-image, .about-us-image1 {
  height: 200%;
  object-fit: cover;
  position: absolute;
}

.about-us-image {
  left: 40%;
  top: -45%;
  width: 45%;
  height: 200%;
}

.about-us-image1 {
  right: 50%;
  bottom: -46%;
  width: 35%;
  height: 150%;
}

.about-us-text h1 {
  width: 900px;
  line-height: 50px;
  top: 75%;
  left: 68%;
  font-size: xxx-large;
}

.about-us-text p {
  width: 900px;
  line-height: 35px;
  top: 91%;
  left: 68%;
  font-size: xx-large;
}
.timeline-event {
  margin-bottom: 8rem;
}

.year {
  font-size: 90px;
}

.event-image {
  width: 385px;
}

.event-content {
  right: 21%;
  font-size: 30px;
  max-width: 700px;
}
}

@media (max-width: 2000px) {
.about-us-image, .about-us-image1 {

  height: auto;
}

.about-us-image {
  left: 45%;
  top: -33%;
  width: 36%;
}

.about-us-image1 {
  right: 48%;
  bottom: -30%;
  width: 30%;
}

.about-us-text h1 {
  width: 600px;
  line-height: 40px;
  top: 70%;
  left: 60%;
  font-size: xx-large;
}

.about-us-text p {
  width: 600px;
  line-height: 25px;
  top: 85%;
  left: 60%;
  font-size: large;
}

.timeline-event {
  margin-bottom: 8rem;
  width: 117%;
}

.year {
  font-size: 80px;
  margin-left: 15%;
}

.event-image {
  width: 340px;
}

.event-content {
  right: 19%;
  font-size: 27px;
  max-width: 650px;
}
}

@media (max-width: 768px) {
  .about-us-container {
      padding: 10px;
  }
  h2, h3 {
      font-size: smaller; 
  }
  p {
      font-size: smaller; 
  }
}

@media (max-width: 1600px) {
.about-us-image, .about-us-image1 {
  width: 30%;
}

.about-us-image {
  left: 47%;
  top: 0%;
}

.about-us-image1 {
  right: 48%;
  bottom: -20%;
  width: 25%;
}

.about-us-text h1 {
  top: 65%;
}

.about-us-text p {
  top: 85%;
  font-size: large;
}

.year {
  font-size: 70px;
  margin-left: 15%;
}

.event-image {
  width: 310px;
}

.event-content {
  right: 15%;
  font-size: 23px;
  max-width: 600px;
}
}

@media (max-width: 1500px) {
.about-us-image {
  left: 47%;
  top: 5%;
}

.event-content {
  right: 13%;
}
}

@media (max-width: 1400px) {
.year {
  font-size: 60px;
  margin-left: 13%;
}

.event-image {
  width: 300px;
}

.event-content {
  right: 17%;
  font-size: 21px;
  max-width: 500px;
}
}

@media (max-width: 1300px) {
.timeline-event {
  margin-bottom: 8rem;
  width: 120%;
}

.year {
  margin-left: 14%;
}

.event-image {
  width: 270px;
}

.event-content {
  right: 15%;
}
}

@media (max-width: 1280px) {
.about-us-text h1 {
  left: 68%;
}

.about-us-text p {
  left: 68%;
}

.about-us-image1 {
  bottom: -10%;
}
}

@media (max-width: 1200px) {
.event-image {
  width: 250px;
}

.event-content {
  right: 16%;
  font-size: 18px;
  max-width: 450px;
}
}

@media (max-width: 1200px) {
.event-content {
    right: 13%;
}
}

@media (max-width: 1024px) {
.about-us-image {
  left: 13%;
  top: 30%;
  width: 31%;
  height: 70%;
}

.about-us-image1 {
  right: 10%;
  bottom: 10%;
  width: 40%;
  height: 70%;
}

.parallax-text {
  margin-top: -10%;
  margin-left: -20%;
}

.timeline-event {
  flex-direction: row; /* Align items in a row */
  align-items: flex-start; /* Align items to the start of the flex container */
  margin-top: 5%;
}


.event-image {
  width:30%;
  margin-right: 13%;
}

.about-us-text h1 {
  top: 50%;
}

.year {
  margin-left: 21%;
  margin-bottom: 20%;
}

.event-content {
  right: 28%;
}
}

@media (max-width: 968px) {
.year {
  font-size: 55px;
}
.event-content {
  right: 26%;
}
}

@media (max-width: 912px) {
/*
.parallax-text {
  margin-top: -25%;
  margin-left: -65%;
}
*/
.parallax-text {
  margin-top: 5%;
  margin-left: -65%;
}
/*
.about-us-image {
  left: 13%;
  top: 30%;
  width: 55%;
  height: 120%;
}
  */

.about-us-image {
  left: 41%;
  top: -13%;
  width: 55%;
  height: 120%;
}

/*
.about-us-image1 {
  right: 5%;
  bottom: 56%;
  width: 55%;
  height: 80%;
}
*/

.about-us-image1 {
  right: 40%;
  bottom: -15%;
  width: 55%;
  height: 80%;
}
/*
.about-us-text h1 {
  left: 45%;
  top: 80%;
}
*/

.about-us-text h1 {
  left: 45%;
  top: 70%;
}
.about-us-text p {
  left: 45%;
  bottom: -150px;
}

.event-content {
  top: 60%;
  right: 23%;
}
}

@media (max-width: 868px) {
.event-content {
  right: 21%;
  font-size: 16px;
  max-width: 440px;
}
}

@media (max-width: 820px) {
.event-content {
  top: 65%;
  left: 32%;
}

.timeline-event {
  margin-top: 10%;
}
}

@media (max-width: 768px) {
.parallax-text {
  margin-top: -10%;
}
.about-us-image {
  left: 50%;
  top: -17%;
}

.about-us-image1 {
  right: 35%;
  bottom: -10%;
}
/*
.about-us-text h1 {
  left: 45%;
  top: 76%;
}
*/ 
.about-us-text h1 {
  left: 50%;
  top: 70%;
}

.about-us-text p {
  left: 50%;
  bottom: -150px;
}

}

@media (max-width: 600px) {

.year {
  font-size: 40px;
}

.event-content {
  font-size: 14px;
}

.timeline-container h1 {
  font-size: 35px;
  margin-bottom: 20px;
}

.about-us-container {
  padding: 2px;
}

.about-us-text h1 {
  font-size: large;
  width: 90%;
}

.about-us-text p {
  font-size: medium; 
  width: 90%;
}

.about-us-image {
  width: 60%; 
  /*
  top: 60%;
  */
  top: 15%;
  left: 50%;
  
  height: auto;
}

.about-us-image1 {
  width: 50%;
  
  right: 40%;
  bottom: 0%;
  
  
  height: auto;
}

}

@media (max-width: 540px) {
.parallax-text {
  margin-top: 5%;
}
.about-us-image {
    width: 60%;
    top: 2%;
}
.about-us-text h1 {
  width: 90%;
  left: 50%;
  font-size: large;
  top: 70%;
}
.about-us-text p {
  width: 80%;
  left: 45%;
  bottom: -10%;
}
}

@media (max-width: 440px) {
.about-us-image1 {
  width: 53%;
  right: 40%;
}

.about-us-text h1 {
  top: 60%;
  font-size: medium;
}

.about-us-text p {
  top: 550px;
}

.timeline-event {
  flex-direction: column; 
  align-items: center; 
}

.year, .event-content, .event-image {
  width: 100%; 
  margin: 10px 0; 
  display: flex; 
  justify-content: center; 
  align-items: center; 
  text-align: center; 
}

.event-image {
  width: 60%;
  margin-bottom: 10%;
}

.event-content {
  top:105%;
  left:50%;
}

.event-content p {
  padding: 0 40px;
}
}

@media (max-width: 375px) {

.about-us-image {
  width: 60%;
  top: 10%;
  left: 63%;
}

.about-us-image1 {
  width: 59%;
  right: 29%;
}
.about-us-text h1 {
  width: 75%;
  left: auto;
  font-size: small;
  top: 81vh;
  line-height: normal;
}
.about-us-text p {
  width: 65%;
  right: auto;
  margin-top: 10px;
  font-size: x-small;
  top: 90vh;
  line-height: normal;
}
}