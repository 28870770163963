.parallax-banner {
    position: relative;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed; /* 关键属性，使背景固定 */
    height: 300px; /* 您可以根据需要调整高度 */
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: white; /* 根据背景图片调整文字颜色 */
    width: 100%;
}

.parallax-banner h1, .parallax-banner p {
    margin: 0; /* 根据需要调整间距 */
}
