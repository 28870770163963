.courseOverview {
    background-color: #FFFCF9;
    min-height: 1000px;

}
.courseOverview h1{
    text-align: center;
    margin-bottom: 20px;
    font-family: 'GTSD Bold';
    font-size: 70px;
    color: #2E2E2E;
    padding-top: 2%;
}

.courseOverview p{
    text-align: left;
    font-family: 'AP Pro Medium';
    font-size: 20px;
    color: #2E2E2E;
    width: 80%;
    margin: 0 auto;
    padding-top: 2%;
}

.courseOverview img{
    display: block;
    margin: 0 auto;
}

.courseOverview h2{
    text-align: center;
    margin-bottom: 20px;
    font-family: 'GTSD Bold';
    font-size: 32px;
    color: #2E2E2E;
    padding-top: 2%;
}
.subpage {
    padding-top: 1%;
    padding-bottom: 1%;
    display: flex;
    justify-content: center;
}

/*

.courseOverview img {
    display: block;
    margin: 240px auto;
    rotate: -90deg;
    /* padding: 0 4px; */
    /* padding-right: 0px; */
    /* transform: translateY(-45px); */
    /* height: 500%; */

    /* padding: 157px 0px; */



@media (max-width: 968px) {
    .courseOverview img {
        transform: rotate(-90deg);
        margin: 200px auto;
        width:100%;
        height:auto;
    }

    .youtube-container {
        width: 30%;
        max-width: 320px;
        height: auto;
        padding-bottom: 50%;
        position: relative;
        margin-left: 4%;
    }

    .youtube-container iframe {
        position: absolute;
        width: 210%;
        height: 80%;
    }
}
