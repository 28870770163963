.function-booking-form {
    flex: 1;
    min-width: 600px; /* 表单的最大宽度，根据需要调整 */
    margin-left: 3%;
    margin-top: 3%;
    min-height: auto;
}

.function-booking-container {
    display: flex;
    justify-content: center;
    align-items: flex-start; /* or 'center' depending on your layout */
    padding-top: 80px; /* Adjust this based on the height of your Header */
    background-color:#F3E8DD ;
  }
  
    /* Swiper Gallery Styles */
    .function-booking-container{
    width: 100%;
    margin-top: 20px;
    }

    .function-booking-image {
    width: 50%;
    margin-top: 20px;
    overflow-x: hidden;
    height: 1100px; /* Adjust if necessary */
    overflow-y: hidden;
    display: flex;
    justify-content: center; /* Centers content horizontally */
    align-items: center; /* Centers content vertically, if needed */
    }

    @media (max-width: 1100px) {
      .function-booking-image {
        display: none;
        }

        .function-booking-form {
          min-width: 85%;
          margin-top: 5%;
          margin-bottom: 5%;
      }   
    }