
@font-face {
  font-family: 'GTSD Light';
  src: url('../assets/fonts/GT-Super/GT-Super-Display-Light-Italic-Trial.otf') format('opentype');
}

@font-face {
  font-family: 'AP Pro Medium';
  src: url('../assets/fonts/Apercu/Apercu Pro Regular.otf') format('opentype');
}
/*
.clubnews-container {

  margin: 0 auto;
  padding: 20px;
  padding-top: 150px;
  box-sizing: border-box;
  background-color: #F3E8DD; 

  min-height: 1000px;
}

.clubnews-content {
  margin-top: -60%;
  display: flex;
  justify-content: space-between;
}
*/
.clubnews-container {
  margin: 0 auto;
  padding: 20px;
  box-sizing: border-box;
 
 /*background: linear-gradient(to bottom, #F3E8DD 40%, #F0E2C3 60%, #D1C0B1 90%); */
  background-color: #F3E8DD ;
  min-height: 1000px;
  position: relative; /* Ensure positioning context */
}

.clubnews-content {
  margin-Top: -1260px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  position: relative;
  z-index: 1;
}

/*
.clubnews-container {
  margin: 0 auto;
  padding: 20px;
  padding-top: 150px; 
  box-sizing: border-box;
  background-color: #F3E8DD;
  min-height: 1000px;
}

.clubnews-content {
  position: relative;
  z-index: 1;
}
*/
.news-item {
  position: relative;

  width: auto;
  height: auto;
  margin-bottom: 30px;
  padding: 20px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Simple box shadow */
  display: flex;
  flex-direction: column;
  align-items: center; /* Center news items */
}

.news-item h2 {
  color: #2D3C34; /* Dark color for the title similar to h2, h3 */
  font-family: 'GTSD Light'; /* Use the custom font-family defined */
  margin-bottom: 20px; /* Space between title and content */
}

.news-item p {
  font-family: 'AP Pro Medium'; /* Use the custom font-family defined */
  color: #2E2E2E; /* Dark color for text similar to .history-section p */
  text-align: justify;
  line-height: 1.6;
  margin-bottom: 20px; /* Space between paragraphs */
}

.news-item img {
  max-width: 100%;
  height: auto;
  border-radius: 4px;
  margin-top: 20px;
}

@media (max-width: 1350px) {
  .articleSection {
    width:50%;
  }
  .smallCategory {
    width:30%;
  }
  
}

@media (max-width: 1024px) {

  .mb-4 {
    width:100%;
  }

  .mb-2 {
    width:100%;
  }
  
}

/* Responsive design considerations */
@media (max-width: 768px) {
  .club-news-container {
    padding: 10px;
  }
  .news-item h2, .news-item p {
    font-size: smaller; /* Smaller font size on mobile devices */
  }

  .clubnews-content {
    flex-direction: column;
    margin-Top: -1295px;
    left: 5%;
  }

  .mb-4 {
    width:100%;
  }

  .mb-2 {
    width:100%;
  }

  .articleSection {
    width:90%;
  }
  .smallCategory {
    width:90%;
  }
}
