.corporate-booking-form {
    flex: 1;
    max-width: 100%; /* 表单的最大宽度，根据需要调整 */
    overflow-y: auto;
}

.corporate-booking-container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    background-color: #F3E8DD;
    width: 100%;
}

.corporate-booking-image {
    width: 60%;
    height: 1100px; /* Ensure this matches the Booking component */
    overflow-y: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.swiper-slide {
    display: flex;
    align-items: center;
    justify-content: center;
}

.swiper-slide img {
    max-width: 100%;
    height: auto;
    margin: auto;
}

@media (max-width: 1024px) {
    .corporate-booking-image {
        display: none;
    }

    .corporate-booking-form {
        margin-top: 5%;
        max-height: 3000px;
        overflow-y: hidden;
    }
}

@media (max-width: 600px) {
    .corporate-booking-form {
        margin-top: 0%;
    }
}
