@font-face {
    font-family: 'GTSD Light'; 
    src: url('../assets/fonts/GT-Super/GT-Super-Display-Light-Trial.otf') format('opentype');
}

body {
    font-family: 'Arial', sans-serif;
    background-color: #2B3D2C;
    color: #000000; /* 这个会影响form的字颜色 */
    padding: 0;
}

.dress-code-container {
    padding: 20px;
    max-width: 100%;
    margin: 0 auto;
    text-align: left;
    margin-top: 120px;
}

.DC-title-container {
    display: flex;
    justify-content: space-between;
    align-items: center; /* Aligns items vertically in the center */
    margin-bottom: 40px;
    padding: 0 100px; /* Add some horizontal padding if needed */
}

.dresscodetitle {
    font-size: 120px;
    font-family: 'GTSD ITALIC BOLD';
    color: #FFFBB3;
    width: 30%;
}

.dresscodetitle-subtitle {
    font-size: 30px;
    width: 40%;
    color: #D7E0D0;
    text-align: left;
    font-family: 'GTSD Light';
    line-height: 40px;
}

.divider {
    width: 90%;
    height: 1px;
    background-color: #D7E0D0; 
    margin: 0 auto 20px auto;
    margin-top: 0%;
}

.section {
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;
}

.section div {
    width: 45%;
    text-align: left;
}

.men {
    margin-left: 5%;
}

.section p {
    font-size: 1.5em;
    margin-bottom: 20px;
    font-family: 'GTSD Italic Bold';
    color: #D7E0D0;
}

.section p1 {
    font-size: 14px;
    margin-bottom: 20px;
    font-family: 'AP Pro Medium';
    width: 80%;
    color: #D7E0D0;
}

.section p2 {
    color: #D7E0D0;
}

.section ul {
    list-style-type: none;
    padding: 0;
}

.section ul li {
    margin-bottom: 10px;
    font-family: 'AP Pro Medium';
    color: #FFFBB3;
}

.note {
    font-size: 0.8em;
    margin-top: 10px;
    font-family: 'AP Pro Medium';
}

.divider2 {
    width: 90%;
    height: 1px;
    background-color: #D7E0D0; 
    margin: 0 auto 20px auto;
    margin-top: 0%;
}

.image-container {
    text-align: center;
    margin-top: 40px;
}

.image-container img {
    width: 100%;
    max-width: 900px;
}

@media (max-width: 1300px) {
    .dresscodetitle {
        width: 100%;
    }
}

@media (max-width: 1100px) {
    .DC-title-container {
        padding: 0 70px;
    }
    
    .dresscodetitle {
        font-size: 70px;
        width: 30%;
    }

    .dresscodetitle-subtitle {
        font-size: 20px;
    }
}

@media (max-width: 768px) {
    .DC-title-container {
        padding: 0 40px;
        align-items: flex-start;
        flex-direction: column;
    }

    .dresscodetitle-subtitle {
        width: 100%;
    }
}

@media (max-width: 425px) {
    .dresscodetitle {
        font-size: 50px;
    }
}
