.practice-facilities-container {
    display: flex;
    height: calc(100vh - 100px); /* Adjust height as needed */
    padding-top: 120px;
    background-color: #F3E8DD;
}

.image-section {
    flex: 1;
    display: flex;
    align-items: center;
}

.image-section img {
    max-width: 100%;
    height: auto;
}

.text-section {
    flex: 1;
    padding: 20px;
    box-sizing: border-box;
    overflow-y: auto; /* Make the text section scrollable */
    font-family: 'GTSD Bold';
    height: calc(100vh - 120px); /* Adjust the height to fit within the container */
}

.text-content {
    padding-right: 20px; /* Optional: Adds padding to avoid content cutting off at the edge */
    padding-left: 5%;
}

.text-section h1 {
    font-size: 64px;
    color: #2E2E2E;
    margin-top: 20px;
}

.text-section p {
    font-size: 1.2em;
    color: #2E2E2E;
    font-family: "AP pro medium";
}

.text-content ul {
    color: #2E2E2E;
    font-family: "AP pro medium";
}

@media (max-width: 1024px) {
    .image-section {
      display: none;
    }
  }