.header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    transition: all 0.3s;
    padding: 10px 20px;
    z-index: 1000;
    border-bottom: 2px solid #FFFBB3;
}

.header-large {
    height: 100px; 
    background-color: #2D3C34;
}

.header-shrink {
    height: 60px; 
    background-color: #2D3C34;
}

.logo {
    height: 80%;
    text-align: center;
    position: absolute;
    left: 49%;
    transform: translateX(-50%);
}

.navbar-interface {
    margin-right: auto; 
    border: none;
    color: #FFFBB3;
    background-color: transparent;
    cursor: pointer;
    padding-left: 2%;
}

.member-login-interface {
    margin-left: auto; 
    border: none;
    color: #FFFBB3;
    background-color: transparent;
    cursor: pointer;
}

.booking-interface {
    border: none;
    color: #FFFBB3;
    background-color: transparent;
    cursor: pointer;
    padding-right: 2%;
}

.booking-interface .MuiSvgIcon-root, 
.navbar-interface .MuiSvgIcon-root, 
.member-login-interface .MuiSvgIcon-root {
    font-size: 50px; 
}

@media (max-width: 820px) {
    .header-container {
        padding: 10px 2px;
    }
}

@media (max-width: 768px) {
    .header-container {
        padding: 10px 0px;
    }

    .logo {
        height: 80%;
    }

    .navbar-interface, .member-login-interface, .booking-interface {
        padding-left: 1%; 
        padding-right: 1%;
    }

    .navbar-interface .MuiSvgIcon-root, 
    .member-login-interface .MuiSvgIcon-root, 
    .booking-interface .MuiSvgIcon-root {
        font-size: 30px; 
    }
}

@media (max-width: 600px) {
    .header-large {
        height: 50px;
        background-color: #2D3C34;
    }
}
