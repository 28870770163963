.Membership-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-top: 40px;
}

  .grid-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center; /* 确保文本在水平方向上居中 */
  }
  .sevendaymemberInfo, .sixdaymemberInfo, .fivedaymemberInfo, .twodaymemberInfo, .intermemberInfo, .juniorInfo, .elementarysixdaymemberInfo, .lifestylesixtypointmemberInfo, .lifestylehundredtenpointmemberInfo {
    background: #F3E8DD;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }


    .juniorTable, .interTable, .twodayTable, .fivedayTable, .sixdayTable, .sevendayTable,.elementarysixdaymemberTable,.lifestylesixtypointmemberTable, .lifestylehundredtenpointmemberTable {
        /* 设置表格字体颜色 */
        color: #333; /* 这里的 #333 是一个示例，你可以根据需要选择颜色 */
    }


  @media (max-width: 1400px) {
    .Membership-grid {
        display: flex;
        flex-direction: column;
    }

    .elementarysixdaymemberImg {
        order: 1;
    }

    .elementarysixdaymemberInfo {
        order: 2;
    }

    .sevendaymemberImg {
        order: 3; /* First in the sequence */
    }

    .sevendaymemberInfo {
        order: 4; /* Second in the sequence */
    }

    .sixdaymemberImg {
        order: 5; /* Third in the sequence */
    }

    .sixdaymemberInfo {
        order: 6; /* Fourth in the sequence */
    }

    .fivedaymemberImg {
        order: 7; /* Fifth in the sequence */
    }

    .fivedaymemberInfo {
        order: 8; /* Sixth in the sequence */
    }

    .twodaymemberImg {
        order: 9; /* Seventh in the sequence */
    }

    .twodaymemberInfo {
        order: 10; /* Eighth in the sequence */
    }

    .intermemberImg {
        order: 11; /* Ninth in the sequence */
    }

    .intermemberInfo {
        order: 12; /* Tenth in the sequence */
    }

    .juniorImg {
        order: 13; /* Eleventh in the sequence */
    }

    .juniorInfo {
        order: 14; /* Twelfth in the sequence */
    }

    .grid-item {
        text-align: left;
    }
}
/*
  @media (max-width: 768px) {
    .Membership-grid {
        display: flex;
        flex-direction: column;
    }
   
    .grid-item img {
        display: none;
    }

    .sevendaymemberInfo {
        order: 1; 
    }

    .sixdaymemberInfo {
        order: 2; 
    }

    .fivedaymemberInfo {
        order: 3; 
    }

    .twodaymemberInfo {
        order: 4; 
    }

    .intermemberInfo {
        order: 5; 
    }

    .juniorInfo {
        order: 6;
    }

    .grid-item {
        text-align: left; 
    }

    .sixdaymemberInfo,
    .fivedaymemberInfo,
    .twodaymemberInfo,
    .intermemberInfo,
    .juniorInfo {
        border-top: 2px solid #2D3C34;
        padding-top: 20px; 
    }

    .button {
      max-width: 150px; 
      width: 100%; 
      margin: 10px auto; 
      text-align: center; 
  }
}
*/ 