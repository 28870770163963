@font-face {
  font-family: 'GTSD Italic Bold'; 
  src: url('../assets/fonts/GT-Super/GT-Super-Display-Bold-Italic-Trial.otf') format('opentype');
}

@font-face {
  font-family: 'AS Light'; 
  src: url('../assets/fonts/Assistant/Assistant-ExtraLight.ttf') format('opentype');
}

@font-face {
  font-family: 'GTSD Bold'; 
  src: url('../assets/fonts/GT-Super/GT-Super-Display-Bold-Trial.otf') format('opentype');
}

@font-face {
  font-family: 'AP Pro Medium'; 
  src: url('../assets/fonts/Apercu/Apercu Pro Regular.otf') format('opentype');
}

@font-face {
  font-family: 'GTST Bold'; 
  src: url('../assets/fonts/GT-Super/GT-Super-Text-Bold-Trial.otf') format('opentype');
}

.drawer-list-item-button .MuiListItemText-primary {
  font-family: 'GTSD Bold', sans-serif;
  font-size: 20px;
  margin-top: 15px;
}


.drawer-list {
    width: 250px;
    background-color: #2D3C34; /* 和 Header/Footer 一致的背景颜色 */
    color: #FFFBB3; /* 和 Header/Footer 一致的文字颜色 */
    height: 400%;
    min-width: 60vw;
    /*
    min-width: 600px;
    */
  }
  
  .drawer-list-item {
    padding: 10px 20px; /* 给列表项一些内边距 */
    border-bottom: 1px solid #FFFBB3; /* 和 Footer 中的线条样式一致 */
    transition: background-color 0.3s; /* 平滑过渡背景色变化 */
  }
  
  .drawer-list-item:hover {
    background-color: #3e4f47; /* 鼠标悬停时的背景颜色 */
  }
  
  .drawer-list-item-button {
    color: inherit; /* 继承文字颜色 */
    text-align: left; /* 文字左对齐 */


    width: 100%; /* Ensure it covers the full width */
    padding: 10px; /* Adjust padding as needed */
    /* Other styles */
  }

  
  /* 小屏幕尺寸调整 */

  @media (max-width: 1024px) {
    .drawer-list {
      height: 400%;
    }
  }

  @media (max-width: 768px) {
    .drawer-list {
      width: 100%; /* 使 Drawer 在小屏幕上宽度填满视窗 */
      min-width: 80vw;
    }
  }

  @media (max-width: 540px) {
    .drawer-list {
      min-width: 100vw;
    }
  }
  

/*
.customDrawerPaper {
  top: var(--header-height-large) !important; 
}
*/
